/* https://www.youtube.com/watch?v=vAUW9YhdQSE */

.row__posters {
  display: flex;
  flex-wrap: nowrap;
  /* overflow-y: hidden; */
  overflow-x: auto;
  min-width: 100%;
  /* padding: 20px; */
}

/* .row__posters::-webkit-scrollbar {
  display: none;
} */

.row__poster {
  /* object-fit: contain;
  width: 100%;
  max-height: 100px; */
  padding: 10px;
  min-width: 180px;
  width: 200px;
  height: 240px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #58564F;
  transition: transform 450ms;
  display: flex;
  flex-direction: column;
}

.row__poster__body {
  max-width: 180px;
  flex: 6;
}

.row__poster__footer {
  flex: 1;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}

.row__poster:hover {
  transform: scale(1.04);
}