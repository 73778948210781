body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.jc-preview-player-slider:hover {
  opacity: 1;
}

.jc-preview-player-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4px;
  height: 4px;
  background: white;
  /* border-radius: 50%; */
  cursor: pointer;
}

.jc-preview-player-slider::-moz-range-thumb {
  width: 4px;
  height: 4px;
  background: white;
  /* border-radius: 50%; */
  cursor: pointer;
}

.jc-preview-player-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  /* background: #d6f6fa; */
  outline: none;
  /* opacity: 0.7; */
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  /* border-radius: 8px; */
  /* background: rgba(255,255,255,0.1); */
  /* box-shadow: 0px 1px 10px 1px black; */
}

.audiopost-chapters-list-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* justify-content: center; */
}

.audiopost-waveform-timestamps {
  margin: 8px 0 8px 0;
  display: flex;
  justify-content: space-between;
  font-size: small;
}

.audiopost-waveform-playPause-loading {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(83, 160, 228);
  border-color: rgb(83, 160, 228);
}

.audiopost-waveform-playPause-button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(83, 160, 228);
  border-color: rgb(83, 160, 228);
  /* bad9f4 */
}

.audiopost-waveform-playPause-button:hover {
  background-color: #1962a1;
  border-color: #1962a1;
  transition: 0.3s;
}

.audiopost-waveform-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jc-chapter-maker-player-slider:hover {
  opacity: 1;
}

.jc-chapter-maker-player-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 100%;
  cursor: pointer;
}

.jc-chapter-maker-player-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 100%;
  cursor: pointer;
}

.jc-chapter-maker-player-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  /* background: #d6f6fa; */
  outline: none;
  /* opacity: 0.7; */
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  /* border-radius: 8px; */
  /* background: rgba(255,255,255,0.1); */
  /* box-shadow: 0px 1px 10px 1px black; */
}

.jc-transcript-editor {
  display: grid;
  /* align-items: center; */
  grid-template-columns: 130px auto;
  grid-template-rows: auto;
  row-gap: 10px;
  column-gap: 20px;
  grid-template-areas:
    "timestamp control"
    "text text"
    "save save";
}

.jc-transcript-editor > .timestamp {
  grid-area: timestamp;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 0 8px;
  border-radius: 6px;
  background-color: #666;
}

.jc-transcript-editor > .control-menu {
  grid-area: control;
  display: flex;
  gap: 10px;
  /* background-color: grey; */
}
/*
.jc-transcript-editor > .text {

} */

.jc-transcript-editor > .editing-box {
  grid-area: text;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2em;
}

.jc-transcript-editor > .display-box {
  grid-area: text;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2em;
}

/* .jc-transcript-editor > .edit-container {
  grid-area: text;
  display: flex;
  flex-wrap: wrap;
  gap: 0.24rem;
} */

.jc-transcript-editor > .control-save {
  grid-area: save;
  display: flex;
  gap: 10px;
  justify-content: end;
}

.jc-transcript-editor-button {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: 80px;
  border: 1px solid hsl(0, 0%, 40%);
  background: #666;
  color: white;
  text-decoration: none;
  padding: 2px 2px;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.75rem;
}

.jc-transcript-segments {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.jc-transcript-segment-editor {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.jc-transcript-segment-editor > .control-menu {
  display: flex;
  gap: 10px;
}

.jc-transcript-segment-editor > .segment {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .jc-transcript-editor-button {
    width: 40px;
  }

  .jc-transcript-editor-button > .label {
    display: none;
  }
}

.jc-transcript-preview-player {
  border-width: 2px 2px 0 2px;
  border-style: solid;
  border-color: rgba(255,255,255,.2);
  margin-bottom: 20px;
  position: sticky;
  background-color: #12263f;
  /* position:-webkit-sticky; */
  top: 0;
  z-index: 100; /* this */
}

.jc-transcript-preview-player > .player-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.jc-transcript-preview-player > .player-container > .playback {
  display: flex;
  justify-content: center;
}

.jc-transcript-preview-player > .player-container > .playback > div {
  display: flex;
  gap: 10px;
  padding: 10px 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.jc-transcript-preview-player > .player-container > .control {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* margin: 10px 0; */
}

.jc-transcript-preview-player > .player-container > .control > .item {
  cursor: pointer;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.jc-transcript-preview-player > .preview-player-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.jc-editor-preview-player {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: #12263f;
}

.transcript-active-word {
 background-color: #2c7be5;
}

.jc-editor-player-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  border-radius: 10px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.jc-transcript-editor-sentence {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-items: center;
}

.jc-transcript-editor-sentence {
  margin-bottom: 20px;
}

.jc-transcript-editor-sentence > .play-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c7be5;
  padding: 3px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.jc-transcript-editor-active-word {
  background-color: #2c7be5;
}

.jc-transcript-editor-input {
  position: sticky;
  top: 0;
  z-index: 100;
}

.jc-transcript-toolbar {
  display: flex;
  background-color: #5382B9;
  border-radius: 0.4rem 0.4rem 0 0;
}

.jc-toolbar-button {
  color: white;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.jc-toolbar-button:first-child {
  border-radius: 0.4rem 0 0 0;
}

.jc-toolbar-button:hover {
  background: #73A9E7;
  border-color: #73A9E7;
}

.jc-toolbar-button:nth-child(2):hover {
  background: #EE6D66;
  border-color: #EE6D66;
}

.jc-transcript-paragraph-time-button {
  cursor: pointer;
}

.jc-transcript-paragraph-time-button:hover {
  text-decoration: underline;
}