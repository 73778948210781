.form-btn-right {
  margin-right: 10px;
}

.source-table{
  margin-top: 20px;
}

.player-hidden {
  display: none;
}

.top-20px{
  margin-top: 20px;
}

.top-40px {
  margin-top: 40px;
}

.registration-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.pay-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.receipt-price {
  float: right;
}

.receipt-list{
  font-size: 20px;
}

/* -------------------------------------
    INVOICE
    Styles for the billing table
------------------------------------- */
.invoice-main {
  background-color: #eee;
  padding: 20px;
}

.invoice {
  margin: 40px auto;
  text-align: left;
  width: 100%;
}
.invoice td {
  padding: 5px 0;
}
.invoice .invoice-items {
  width: 100%;
}
.invoice .invoice-items td {
  border-top: #333 1px solid;
}
.invoice .invoice-items .total td {
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  font-weight: 700;
}

.invoice-list {
  margin: 1px;
}

.last {
  margin-bottom: 0;
}

.first {
  margin-top: 0;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.alignleft {
  text-align: left;
}

.clear {
  clear: both;
}

#app-body {
  display: none;
}

.margin-right-10px {
  margin-right: 10px
}

.receipt-page-logo {
  max-width: 2.5rem;
}

.authentication {
  margin-top: 50px;
}

.rss-button {
  margin: 0 10px;
}

.widget-code-section {
  margin-top: 20px;
}

.artwork-img {
  max-width: 64px;
}

.spec-button {
  margin-right: 10px;
}

.spec-content-message {
  margin-top: 20px;
}

.slider-multiple-inputs {
  margin-top: 60px;
  margin-bottom: 60px;
}

.trailer-spec-notes{
  margin-top: 10px;
}

.trailer-result-demo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.jc-link:hover {
  text-decoration: underline;
}

.left-20px {
  margin-left: 20px;
}

.click-link {
  cursor:pointer
}

#show-settings {
  margin-right: 10px;
}

.jc-dropdown-button:hover {
  cursor: pointer;
}

.jc-media-scrollbar::-webkit-scrollbar {
  height: 12px;
}


/* Track */
.jc-media-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background: #f1f1f1;
  /* border-radius: 10px; */
}

/* Handle */
.jc-media-scrollbar::-webkit-scrollbar-thumb {
  background: #059DFB;
  /* border-radius: 10px; */
}

.card-body-button {
  cursor: pointer;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.ql-editor > p {
  margin-bottom: 10px;
}

.ql-editor > h1 {
  margin-bottom: 10px;
}

.ql-editor > h2 {
  margin-bottom: 10px;
}

.ql-editor > h3 {
  margin-bottom: 10px;
}

.ql-editor > h4 {
  margin-bottom: 10px;
}

.ql-editor > h5 {
  margin-bottom: 10px;
}

.ql-editor > li {
  margin-bottom: 10px;
}

@media (max-width: 400px) {
  .small-screen-flex {
    flex-direction: column;
  }
}