.mobile-player-controls {
  padding: 20px;
  /* min-height: 200px; */
}

.mobile-player-progress-control > .miscellaneous > .player-progress-bar {
  width: 100%;
  position: relative;
}

.mobile-player-progress-control > .miscellaneous > .player-progress-bar > input {
  width: 99%;
  position: absolute;
  opacity: 0;
}

.mobile-player-progress-control > .miscellaneous > .player-progress-bar > progress {
  width: 100%;
}


.mobile-player-progress-control > .miscellaneous > .player-times {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.mobile-player-controls > .mobile-player-buttons {
  margin-top: 40px;
  margin-bottom: 40px;
  /* height: 100px; */
  /* background-color: cadetblue; */
  display: flex;  
  justify-content: space-around;
  align-items: center;
}

.player-meta > .player-meta-title { 
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.player-meta > .player-meta-artwork {
  display: flex;
  justify-content: center;
}

.player-meta > .player-meta-subtitle { 
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
}

.player-meta > .menu {
  display: flex;
  justify-content: center;
}